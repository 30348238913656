function confirm(url, title, message) {
	alertify.confirm(title,message,
		function(){
			$.post(url, {'_method':'delete','_token':$('input[name=_token]').val()})
			.done(function(){location.reload()});
		},
		null);
}

$(function(){
	$('.toggle-menu').click(function(){
		$('.sidebar').toggle();
	});
	$('.account__profile').click(function(){
		$(".dropdown-profile").toggle();
	});
	$('.option-checkbox').click(function(){
		$(".dropdown-checkbox").toggle();
	});
	$(document).click(function(e){
		if (!$(e.target).is('.account__profile') && !$(e.target).parents().is('.account__profile')) {
	    	$(".dropdown-profile").hide();
	   	}
		if (!$(e.target).is('.option-checkbox') && !$(e.target).parents().is('.option-checkbox')) {
			$(".dropdown-checkbox").hide();
		}
	});

	var menu = location.pathname.split("/")[1];
	$('#'+menu+'Menu').addClass('selected');
})
